export const initialState = {
  articles: [],
};

export const setArticles = (articles) => {
  return {
    type: 'SET_ARTICLES',
    payload: articles,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_ARTICLES':
      return {
        ...state,
        articles: action.payload,
      };
    default:
      return state;
  }
}   