import { combineReducers } from "redux";

import terms from "./terms";
import font from "./font";
import user from "./user";
import regex from "./regex";
import application from "./application";
import chat from "./chat";
import articles from "./articles";
import sections from "./section";
const rootReducer = combineReducers({
  terms,
  font,
  user,
  regex,
  application,
  chat,
  articles,
  sections,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
