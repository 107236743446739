import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { ChatMessage , ADD_CHAT_MESSAGE, CLEAR_CHAT_HISTORY, REMOVE_LAST_CHAT_MESSAGE, SET_CHAT_HISTORY, SET_NAMESPACE } from "../reducers/chat";

export const useChat = () => {
  const messages = useSelector((state: RootState) => state.chat.messages);
  const namespace = useSelector((state: RootState) => state.chat.namespace);
  const dispatch = useDispatch();

  const addMessage = (message: ChatMessage) => {
    dispatch({
      type: ADD_CHAT_MESSAGE,
      payload: message,
    });
  };

  const removeLastMessage = () => {
      dispatch({
        type: REMOVE_LAST_CHAT_MESSAGE,
      });
  };

  const clearChat = () => {
    dispatch({
      type: CLEAR_CHAT_HISTORY,
    });
  };

  const setChat = (messages: ChatMessage[]) => {
    dispatch({
      type: SET_CHAT_HISTORY,
      payload: messages,
    });
  };

  const setNamespace = (namespace: string) => {
    dispatch({
      type: SET_NAMESPACE,
      payload: namespace,
    });
  };

  return { messages, namespace, addMessage, clearChat, setChat,removeLastMessage, setNamespace };
};

