export const initialState = {
    sections: [],
  };

  export const setSections = (sections) => {
    return {
      type: 'SET_SECTIONS',
      payload: sections,
    };
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case 'SET_SECTIONS':
        return {
          ...state,
          sections: action.payload,
        };
      default:
        return state;
    }
  }   