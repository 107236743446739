import React, { useEffect, useState } from "react";

import { Definition, TitleContainer } from "../styled";
import { Section } from "../ui/Grid";
import JsxParser from "react-jsx-parser";

import ClauseLink from "../ClauseLink";

import PropTypes from "prop-types";

import { IClause } from "../../types/IClause";

const ClauseDefinition = (props) => {

  const getWordRegex = (text) => {
    //const alts = term.alternativeTerms.map((r) => r.word).join("|");
    //const regex = (alts ? alts + "|" : "") + term.word;
    const termReg = text
      .replaceAll("(", "\\(")
      .replaceAll(")", "\\)")
      .replaceAll(".", "\\.");

    if (text[text.length - 1] === ")") {
      return new RegExp("\\b" + termReg, "gi");
    } else {
      return new RegExp("\\b" + termReg + "\\b", "gi");
    }
  };

  const replaceChildren = (definition) => {
    const sections = props?.analysis?.sections?.sections?.map((r => ({
      id: r.id,
      aliases: [{word: r.word, id: r.id, match: r.word}],
      word: r.word
    }))) ?? []
 
    let children = [...props.clauses, ...sections]
      .filter((r) => props.clause.children.includes(r.word))
      .map((r) => {
        const t = r.aliases.map((s) => {
          return {
            id: r.id,
            word: r.word,
            alias: s.word,
            aliasId: s.id,
            aliasMatch: s.match,
          };
        });
        return t;
      });

    if (children.length > 0)
      children = children
        .reduce((prev, current) => [...prev, ...current])
        .sort((a, b) => (a.alias.length > b.alias.length ? -1 : 1));

    for (var i = 0; i < children.length; i++) {
      const regEx = getWordRegex(children[i].alias);
      definition = definition.replaceAll(regEx, children[i].aliasId);
    }

    for (var i = 0; i < children.length; i++) {
      definition = definition.replaceAll(
        children[i].aliasId,
        '<ClauseLink word="'.concat(
          children[i].word,
          '"',
          'text="',
          children[i].alias,
          '"',
          " handleClauseLinkClick={handleClauseLinkClick} isCurrent={",
          (props.clause.id === children[i].id).toString(),
          "} />"
        )
      );
    }

    return definition;
  };

  const getDefinition = (definition) => {
    definition = replaceChildren(definition);
    return definition;
  };

  return (
    <>
      {(props.clause.clauseType === "TERM" ||
        props.clause.definedDefinition.length > 0) && (
        <Definition>
          <TitleContainer>
            {props.clause.clauseType === "TERM" && <h3>Indexed Term:</h3>}
          </TitleContainer>

          {props.clause.definedDefinition.length === 0 &&
            props.clause.clauseType === "TERM" && (
              <p>No indexed definition found.</p>
            )}

          {props.clause.definedDefinition.map((d, i) => {
            return (
              <div key={i}>
                <JsxParser
                  components={{ ClauseLink }}
                  bindings={{
                    handleClauseLinkClick: (word) =>
                      props.handleClauseLinkClick(word),
                  }}
                  jsx={getDefinition(d)}
                />
              </div>
            );
          })}
        </Definition>
      )}

      {(props.clause.clauseType === "TERM" ||
        props.clause.definedDefinition.length > 0) && (
        <Definition>
          <TitleContainer>
            {props.clause.clauseType === "TERM" && <h3>Inline Definition:</h3>}
          </TitleContainer>

          {props.clause.inlineDefinition.length === 0 &&
            props.clause.clauseType === "TERM" && (
              <p>No inline definition found.</p>
            )}
          {props.clause.inlineDefinition.map((d, i) => {
            return (
              <div key={i}>
                <JsxParser
                  components={{ ClauseLink }}
                  bindings={{
                    handleClauseLinkClick: (word) =>
                      props.handleClauseLinkClick(word),
                  }}
                  jsx={getDefinition(d)}
                />
              </div>
            );
          })}
        </Definition>
      )}

      <Definition>
        {props.clause.tocDefinition.map((d, i) => {
          return (
            <div key={i}>
              <JsxParser
                components={{ ClauseLink }}
                bindings={{
                  handleClauseLinkClick: (word) =>
                    props.handleClauseLinkClick(word),
                }}
                jsx={getDefinition(d)}
              />
            </div>
          );
        })}
      </Definition>

      <Definition>
        {props.clause.referenceDefinition.map((d, i) => {
          return (
            <div key={i}>
              <JsxParser
                components={{ ClauseLink }}
                bindings={{
                  handleClauseLinkClick: (word) =>
                    props.handleClauseLinkClick(word),
                }}
                jsx={getDefinition(d)}
              />
            </div>
          );
        })}
      </Definition>
    </>
  );
};

ClauseDefinition.propTypes = {
  clause: PropTypes.object,
  clauses: PropTypes.array,
  analysis: PropTypes.object,
  handleClauseLinkClick: PropTypes.func,
};

ClauseDefinition.defaultProps = {};

export default ClauseDefinition;
