import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import PropTypes from "prop-types";

import TermsLoading from "../../components/TermsLoading";

import extractors from "../../extractors";
import server from "../../../utilities/server/index";
import analyzeDocument from "../../anaylsis/regex/analyzeDocument";
import convertRegexResponseData from "../../anaylsis/regex/convertResponseData";
import convertAiResponseData from "../../anaylsis/ai/convertResponseData";

import { setAnalysis, setAnalysisType } from "../../actions/terms";

import {
  LICENSE_TYPES,
  ANALYSIS_ENGINE,
} from "../../interfaces/IUserInterface.ts";
import { setSections } from "../../reducers/section";
import { setArticles } from "../../reducers/articles";

const Analyzing = (props) => {
  const [loadingText, setLoadingText] = useState();

  useEffect(() => {
    const runAnalysis = async (html) => {
      //request analysis
      let analysis;
      let converted;

      analysis = analyzeDocument(html);
      converted = convertRegexResponseData(analysis);
      console.log("---------------------------" )
      console.log(converted )
      props.setAnalysis(converted);
      props.setSections(converted.sections);
      props.setArticles(converted.articles);
    };

    setLoadingText("Extracting text from document.");
    extractors.word.document(function (html) {
      setLoadingText("License Retrieved.  Analyzing Document.");
      runAnalysis(html);
    });
  }, []);

  return <TermsLoading text={loadingText} />;
};

Analyzing.propTypes = {
  accessToken: PropTypes.string,
};

Analyzing.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setAnalysis: (analysis: any) => dispatch(setAnalysis(analysis)),
  setSections: (sections: any) => dispatch(setSections(sections)),
  setArticles: (articles: any) => dispatch(setArticles(articles)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Analyzing);
