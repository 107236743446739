"use strict";

import * as React from "react";

var Modal = require("react-modal");
import { ModalHeader, ModalBody, ModalFooter, PatentLink } from "./styled";

interface IPatentProps {}

interface IPatentState {
  modalIsOpen: boolean;
}

class Patent extends React.Component<IPatentProps, IPatentState> {
  constructor(props: IPatentProps) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  render() {
    return (
      <>
        <PatentLink className="header-ignore" href="https://spectaclesxai.com/patent">
          Protected by US Patent 11354501
        </PatentLink>

        <p style={{fontSize: '12px', color: 'gray'}}>v0.2.5</p>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={{ content: { inset: "0" } }}
        >
          <ModalHeader>
            <img src="../../../assets/spectacles-logo.png" />
          </ModalHeader>
          <ModalBody>
            <p>
              Spectacles products are protected by patents in the U.S. and
              elsewhere. This website is provided to satisfy the virtual patent
              marking provisions of Section 16 of the America Invents Act and to
              further serve as notice under 35 U.S.C. §287(a). The following
              list of Spectacles products may not be all inclusive, and other
              Spectacles products not listed here may be protected by one or
              more patents:{" "}
            </p>

            <p>Spectacles: US11354501B2.</p>

            <p>Last Updated: July 11, 2022</p>
          </ModalBody>
          <ModalFooter>
            <button type="button" className="cancel" onClick={this.closeModal}>
              Okay
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Patent;
