import { toast } from "react-toastify";
import { textDiff } from "../../utilities/textDiff";

export const insertParagraph = async (prompt: string) => {
  try {
    await Word.run(async (context) => {
      const docBody = context.document.body;
      docBody.insertParagraph(prompt, Word.InsertLocation.start);
      await context.sync();
    });
  } catch (error) {
    console.error(error);
  }
};

export const getFile = async (): Promise<{fileContent: string, documentTitle: string}> => {
  return new Promise((resolve, reject) => {
    Word.run(async (context) => {
      const body = context.document.body;
      const bodyHtml = body.getHtml();
      // Load the document properties before accessing them
      context.load(context.document.properties, 'title');

      return context.sync().then(() => {
        resolve({
          fileContent: bodyHtml.value,
          documentTitle: context.document.properties.title
        });
      }).catch((error) => {
        toast.error(error)
        reject(error)
      });
    });
  });
};

// export const getFile = async (): Promise<Uint8Array> => {
//   return new Promise((resolve, reject) => {
//     Office.context.document.getFileAsync(
//       Office.FileType.Compressed, // Use Compressed to get the file as a byte array
//       { sliceSize: 65536 /* size in bytes */ },
//       (result) => {
//         if (result.status === Office.AsyncResultStatus.Failed) {
//           reject(result.error);
//         } else {
//           const file = result.value;
//           const sliceCount = file.sliceCount;
//           let fileContent = []; // Use an array to collect byte slices
//           let slicesReceived = 0;

//           // Function to get slice content
//           const getSliceContent = (sliceIndex: number) => {
//             file.getSliceAsync(sliceIndex, (sliceResult) => {
//               if (sliceResult.status === Office.AsyncResultStatus.Failed) {
//                 file.closeAsync();
//                 reject(sliceResult.error);
//               } else {
//                 const slice = sliceResult.value;
//                 fileContent.push(new Uint8Array(slice.data)); // Convert slice data to Uint8Array and collect
//                 slicesReceived++;
//                 if (slicesReceived === sliceCount) {
//                   file.closeAsync();
//                   const combinedContent = new Uint8Array(
//                     fileContent.reduce(
//                       (acc, val) => acc.concat(Array.from(val)),
//                       []
//                     )
//                   );

//                   resolve(combinedContent); // Resolve with the array of byte slices
//                 } else {
//                   getSliceContent(slicesReceived);
//                 }
//               }
//             });
//           };

//           // Start getting slices
//           getSliceContent(0);
//         }
//       }
//     );
//   });
// };

export const tryCatch = async (callback: () => Promise<void>) => {
  try {
    await callback();
  } catch (error) {
    console.error(error);
  }
};

export const createContentControl = async () => {
  await Word.run(async (context) => {
    const serviceNameRange = context.document.getSelection();
    const serviceNameContentControl = serviceNameRange.insertContentControl();
    serviceNameContentControl.title = "Service Name";
    serviceNameContentControl.tag = "serviceName";
    serviceNameContentControl.appearance = "Tags";
    serviceNameContentControl.color = "blue";

    await context.sync();
  });
};

export const replaceContentInControl = async () => {
  await Word.run(async (context) => {
    const serviceNameContentControl = context.document.contentControls
      .getByTag("serviceName")
      .getFirst();
    serviceNameContentControl.insertText(
      "Fabrikam Online Productivity Suite",
      Word.InsertLocation.replace
    );

    await context.sync();
  });
};

export const putRedline = async (text: string) => {
  await Word.run(async (context) => {
    var body = context.document.body;
    var searchResults = body.search(text, { matchCase: true });
    context.load(searchResults);

    await context.sync();
    for (var i = 0; i < searchResults.items.length; i++) {
      searchResults.items[i].font.color = "red"; // Change color to red
      searchResults.items[i].font.strikeThrough = true; // Strike through the text
    }
    return await context.sync();
  }).catch(function (error) {
    console.error(error);
  });
};

export function stripHtmlAndSpecialChars(text: string) {
  const div = document.createElement('div');
  div.innerHTML = text;
  // This line gets the text content from the div element (using textContent or innerText as fallback),
  // removes newlines/tabs/carriage returns by replacing them with spaces,
  // then replaces multiple spaces with single spaces, and trims whitespace from ends
  return (div.textContent || div.innerText || '').replace(/[\r\n\t]/g, ' ').replace(/\s+/g, ' ').trim();
}



export const putRedlinesOffice = async (suggestedText: string) => {
  return await Word.run(async (context) => {
    const selection = context.document.getSelection();
    context.load(selection, 'text, font');
    await context.sync();

    const oldText = selection.text;
    const cleanNewText = stripHtmlAndSpecialChars(suggestedText);
    const result2 = textDiff(oldText, cleanNewText);

    // Clear the selection
    selection.clear();
    await context.sync();

    // Insert text directly in the selection
    for (const diff of result2) {
      const range = selection.insertText(diff.value + " ", Word.InsertLocation.end);
      
      if (diff.added) {
        range.font.color = 'blue';
        range.font.strikeThrough = false;
      } else if (diff.removed) {
        range.font.color = 'red';
        range.font.strikeThrough = true;
      } else {
        range.font.color = 'black';
        range.font.strikeThrough = false;
      }
    }

    await context.sync();
  });
};

export const getSectionContent = async (sectionName: string) => {
  await Word.run(async (context) => {
    const body = context.document.body;
    const paragraphs = body.paragraphs;
    context.load(paragraphs, "text, style, font");

    await context.sync();

    let sectionFound = false;
    let sectionContent = "";
    let sectionFont = null;
    if (sectionName === "") {
      console.log("You have to input section name to search");
    } else {
      paragraphs.items.forEach((paragraph) => {
        if (paragraph.text.trim() === sectionName) {
          sectionFound = true;
          sectionFont = paragraph.font.toJSON();
        } else if (
          sectionFound &&
          paragraph.text &&
          JSON.stringify(sectionFont) ===
            JSON.stringify(paragraph.font.toJSON())
        ) {
          sectionFound = false;
        }

        if (sectionFound) {
          sectionContent += paragraph.text + "\n";
        }
      });
      console.log("Content of Section:", sectionContent);
    }
  }).catch(function (error) {
    console.error(error);
  });
};

export const replaceText = async (oldText: string, newText: string) => {
  await Word.run(async (context) => {
    var body = context.document.body;
    var searchResults = body.search(oldText, { matchCase: true });
    context.load(searchResults, "text");
    await context.sync();
    for (let i = 0; i < searchResults.items.length; i++) {
      searchResults.items[i].insertText(newText, Word.InsertLocation.replace);
    }

    return await context.sync();
  }).catch(function (error) {
    console.error(error);
  });
};

export const getSections = async () => {
  try {
    const sectionsArray = [];
    await Word.run(async (context) => {
      const sections = context.document.sections;
      context.load(sections, "body/style");

      await context.sync();
      console.log("count", sections.items.length);

      for (let i = 0; i < sections.items.length; i++) {
        let jsonSection = {
          title: "",
          content: "",
        };
        const section = sections.items[i];
        const sectionBody = section.body;
        context.load(sectionBody, "text, paragraphs");

        await context.sync();
        if (sectionBody.text !== "") {
          jsonSection.title = sectionBody.paragraphs.items[0].text.trim();
          jsonSection.content = sectionBody.paragraphs.items
            .filter((_, index) => index !== 0)
            .reduce((accumulator, item) => {
              return accumulator
                ? accumulator + "\n" + item.text
                : accumulator + item.text;
            }, "");
          sectionsArray.push(jsonSection);
        }
      }
    });
    return sectionsArray;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

export const getHighlightedText = async () => {
  try {
    let highlightedTexts = [];
    await Word.run(async (context) => {
      let paragraphs = context.document.body.paragraphs;
      paragraphs.load("text, font/highlightColor");

      await context.sync();
      let sectionText = "";
      for (let i = 0; i < paragraphs.items.length; i++) {
        let paragraph = paragraphs.items[i];

        if (paragraph.font.highlightColor !== null) {
          if (paragraph.text.trim() !== "") {
            sectionText += paragraph.text;
          } else {
            sectionText += "\n";
          }
        } else {
          if (sectionText) {
            highlightedTexts.push(sectionText);
            sectionText = "";
          }
        }
      }
    });
    return highlightedTexts;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getSelectedText = async () => {
  try {
    let selectedText = "";
    await Word.run(async (context) => {
      var selectedRange = context.document.getSelection();
      context.load(selectedRange, "text");
      await context.sync();
      selectedText = selectedRange.text;
    });
    return selectedText;
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const insertRevisedContent = async (
  targetText: string,
  revisedText: string
) => {
  await Word.run(async (context) => {
    var body = context.document.body;
    body.load("paragraphs");

    await context.sync();
    var paragraphs = body.paragraphs;
    for (var i = 0; i < paragraphs.items.length; i++) {
      if (paragraphs.items[i].text.includes(targetText)) {
        let newParagraph = paragraphs.items[i].insertParagraph(
          revisedText,
          "After"
        );
        newParagraph.font.color = "red";
        newParagraph.font.highlightColor = null;
        newParagraph.font.strikeThrough = true;
        break;
      }
    }
    const passThroughValue = undefined;
    return context.sync(passThroughValue);
  }).catch((error) => {
    console.log("Error: " + error.message);
  });
};

export const insertContentNextSelected = async (revisedText: string) => {
  await Word.run(async (context) => {
    var selectedRange = context.document.getSelection();

    context.load(selectedRange, "text, paragraphs");

    try {
      await context.sync();
      let newText = selectedRange.insertParagraph(
        revisedText,
        Word.InsertLocation.after
      );
      newText.font.set({
        name: "Calibri",
        bold: false,
        italic: false,
        underline: Word.UnderlineType.none,
        color: "red",
      });
      await context.sync();
    } catch (error) {
      console.log("Error: " + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
    }
  });
};

export const applyStrikethrough = async () => {
  await Word.run(async (context) => {
    const range = context.document.getSelection();
    range.load("text, paragraphs");
    await context.sync();

    if (range.text.length > 0) {
      range.font.strikeThrough = true;
      await context.sync();
    }
  });
};

export const removeTextAndAddNewContent = async (newContent: string) => {
  await Word.run(async (context) => {
    const range = context.document.getSelection();
    // Clear the text in the selected range
    range.clear();
    // Insert new content at the cleared range's location
    range.insertText(newContent, Word.InsertLocation.replace);
    await context.sync();
  });
};

export const listenToDocumentChanges = (callback: (event: Office.DocumentEventArgs) => void) => {
  try {
    Office.context.document.addHandlerAsync(
      Office.EventType.ActiveViewChanged,
      callback,
      (result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          console.error('Failed to add document focus handler:', result.error);
        }
      }
    );
  } catch (error) {
    console.error('Error setting up document focus listener:', error);
  }
};

// To remove the listener when needed:
export const removeDocumentChangeListener = (callback: (event: Office.DocumentEventArgs) => void) => {
  try {
    Office.context.document.removeHandlerAsync(
      Office.EventType.ActiveViewChanged,
      { handler: callback },
      (result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          console.error('Failed to remove document change handler:', result.error);
        }
      }
    );
  } catch (error) {
    console.error('Error removing document change listener:', error);
  }
};