import { IClause } from "../../types/IClause";
import clauseComparer from "../../../utilities/clauseComparer";

export default function (data, opts) {
  function getTerms() {
    if (!data.terms) return [];

    return data.terms.map((c) => {
      return {
        id: c.id,
        word: c.word,
        clauseType: c.type.toUpperCase(),
        title: c.title,
        children: c.children,
        definedDefinition: c.definedDefinition
          ? c.definedDefinition.map((r) => r.html)
          : [],
        inlineDefinition: c.definition ? c.definition.map((r) => r.html) : [],
        tocDefinition: [],
        referenceDefinition: [],
        aliases: c.alternativeTerms,
      };
    });
  }

  function getSections() {
    if (!data.sections) return [];

    return data.sections.map((c) => {
      return {
        id: c.id,
        word: c.word,
        clauseType: c.type.toUpperCase(),
        title: c.title,
        children: c.children,
        definedDefinition: c.definedDefinition
          ? c.definedDefinition.map((r) => r.html)
          : [],
        inlineDefinition: c.definition ? c.definition.map((r) => r.html) : [],
        tocDefinition: [],
        referenceDefinition: [],
        aliases: c.alternativeTerms,
      };
    });
  }

  function getArticles() {
    if (!data.articles) return [];

    return data.articles.map((c) => {
      return {
        id: c.id,
        word: c.word,
        clauseType: c.type.toUpperCase(),
        title: c.title,
        children: c.children,
        definedDefinition: c.definedDefinition
          ? c.definedDefinition.map((r) => r.html)
          : [],
        inlineDefinition: c.definition ? c.definition.map((r) => r.html) : [],
        tocDefinition: [],
        referenceDefinition: [],
        aliases: c.alternativeTerms,
      };
    });
  }

  function getExhibits() {
    if (!data.exhibits) return [];

    return data.exhibits.map((c) => {
      return {
        id: c.id,
        word: c.word,
        clauseType: c.type.toUpperCase(),
        title: c.title,
        children: c.children,
        definedDefinition: c.definedDefinition
          ? c.definedDefinition.map((r) => r.html)
          : [],
        inlineDefinition: c.definition ? c.definition.map((r) => r.html) : [],
        tocDefinition: [],
        referenceDefinition: [],
        aliases: c.alternativeTerms,
      };
    });
  }

  function getAnnexes() {
    if (!data.annexes) return [];

    return data.annexes.map((c) => {
      return {
        id: c.id,
        word: c.word,
        clauseType: c.type.toUpperCase(),
        title: c.title,
        children: c.children,
        definedDefinition: c.definedDefinition
          ? c.definedDefinition.map((r) => r.html)
          : [],
        inlineDefinition: c.definition ? c.definition.map((r) => r.html) : [],
        tocDefinition: [],
        referenceDefinition: [],
        aliases: c.alternativeTerms,
      };
    });
  }

  function getSchedules() {
    if (!data.schedules) return [];

    return data.schedules.map((c) => {
      return {
        id: c.id,
        word: c.word,
        clauseType: c.type.toUpperCase(),
        title: c.title,
        children: c.children,
        definedDefinition: c.definedDefinition
          ? c.definedDefinition.map((r) => r.html)
          : [],
        inlineDefinition: c.definition ? c.definition.map((r) => r.html) : [],
        tocDefinition: [],
        referenceDefinition: [],
        aliases: c.alternativeTerms,
      };
    });
  }

  const terms = getTerms();
  const sections = getSections();
  const annexes = getAnnexes();
  const articles = getArticles();
  const exhibits = getExhibits();
  const schedules = getSchedules();
  const clauses = terms
    .concat(sections)
    .concat(annexes)
    .concat(articles)
    .concat(exhibits)
    .concat(schedules);


  return {
    clauses,
    articles,
    sections,
    level_0_nodes: clauses
      .filter((r) => r.clauseType != "TERM")
      .map((r) => r.word),
    graph: {
      directed: true,
      nodes: [],
      links: clauses
        .map((r) =>
          r.children.map((s) => {
            return { source: r.word, target: s };
          })
        )
        .reduce(
          (accumulator, currentValue) => accumulator.concat(currentValue),
          []
        ),
    },
    raw: data.raw,
  };
}
