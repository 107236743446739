export const SET_CHAT_HISTORY = 'SET_CHAT_HISTORY';
export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE';
export const CLEAR_CHAT_HISTORY = 'CLEAR_CHAT_HISTORY';
export const REMOVE_LAST_CHAT_MESSAGE = 'REMOVE_LAST_CHAT_MESSAGE';
export const SET_NAMESPACE = 'SET_NAMESPACE';

export interface ChatMessage {
  username: string;
  content: string;
  renderContent?: JSX.Element;
  created: string;
  type: "user" | "assistant";
}

export interface ChatState {
  messages: ChatMessage[];
  namespace: string;
}

export const initialState: ChatState = {
  messages: [],
  namespace: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CHAT_HISTORY:
      return { ...state, messages: action.payload };
    case ADD_CHAT_MESSAGE:
      return { ...state, messages: [...state.messages, action.payload] };
    case CLEAR_CHAT_HISTORY:
      return { ...state, messages: [] };
    case REMOVE_LAST_CHAT_MESSAGE:
      return { ...state, messages: state.messages.slice(0, -1) };
    case SET_NAMESPACE:
      return { ...state, namespace: action.payload };
    default:
      return state;
  }
}
